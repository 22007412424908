// Here you can add other styles

/*.c-sidebar-brand {
  color: #fff!important;
  background: #9e1b9c!important;
}*/

.highcharts-credits {
  visibility: hidden !important;
}

body {
  font-family: 'Montserrat', sans-serif !important;
}