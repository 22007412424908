@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
// If you want to override variables do it here
@import "variables";

// Import CoreUI styles
@import "~@coreui/coreui/scss/coreui.scss";

// If you want to add something do it here
@import "custom";

//@import "variables";

