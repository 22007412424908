// Variable overrides
@import "~@coreui/coreui/scss/functions";
@import "~@coreui/coreui/scss/variables/colors";

//functions


//Colors

$agros-yellow: #29b7e3;
$agros-yellow-dark: #29b7e3;
$agros-black-grey: #111;
$agros-black: #093559;
$agros-black-transparent: rgba($agros-black, .8);
$agros-green: #093559;

$eoc-blue: #3498db ;
$eoc-orange: #ffc72c;
$border-color: $gray-200;

$theme-colors: (
  "eoc-blue": $eoc-blue,
  "eoc-orange": $eoc-orange,
  "agros-yellow": $agros-yellow,
  "agros-yellow-dark": $agros-yellow-dark,
  "agros-black": $agros-black,
  "agros-green": $agros-green,
  "agros-black-transparent": $agros-black-transparent,
);

//Intermediate Sidebar variables
$sidebar-agros-color: $gray-900;
$sidebar-agros-bg: $agros-black;
$sidebar-agros-nav-link-color: $agros-yellow;
$sidebar-agros-borders:  (
  right: 1px solid rgba(darken($gray-200, 20%), .5)
);
$sidebar-agros-brand-color:  $white;
$sidebar-agros-brand-bg:     $agros-black;
$sidebar-agros-header-bg: rgba($agros-black, .2);
$sidebar-agros-nav-title-color:      rgba($white, .9);
$sidebar-agros-nav-link-color:       rgba($white, .9);
$sidebar-agros-nav-link-bg:          transparent;
$sidebar-agros-nav-link-icon-color:  rgba($white, .9);
$sidebar-agros-nav-link-borders:     0;
$sidebar-agros-nav-link-hover-color:       $white;
$sidebar-agros-nav-link-hover-bg:          $agros-yellow;
$sidebar-agros-nav-link-hover-icon-color:  $white !default;
$sidebar-agros-nav-link-hover-borders:     0 !default;
$sidebar-agros-nav-link-active-color:       rgba($white, 1) !default;
$sidebar-agros-nav-link-active-bg:          rgba($agros-yellow, 1) !default;
$sidebar-agros-nav-link-active-icon-color:  $white !default;
$sidebar-agros-nav-link-active-borders:     0 !default;

$sidebar-agros-nav-dropdown-color:                  rgba($agros-black, .8) !default;
$sidebar-agros-nav-dropdown-bg:                   rgba($agros-yellow, .05) !default;
$sidebar-agros-nav-dropdown-borders:                0 !default;
$sidebar-agros-nav-dropdown-indicator-color:        rgba($agros-black, .5) !default;
$sidebar-agros-nav-dropdown-indicator:              str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='#{$sidebar-agros-nav-dropdown-indicator-color}' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E"), "#", "%23") !default;
$sidebar-agros-nav-dropdown-indicator-hover-color:  $sidebar-agros-nav-link-hover-color !default;
$sidebar-agros-nav-dropdown-indicator-hover:        str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='#{$sidebar-agros-nav-dropdown-indicator-hover-color}' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E"), "#", "%23") !default;

$sidebar-agros-nav-tabs-border-color:              rgba(darken($border-color, 20%), .5) !default;
$sidebar-agros-nav-tabs-link-active-bg:            $white !default;
$sidebar-agros-nav-tabs-link-active-border-color:  rgba(darken($border-color, 20%), .5) rgba(darken($border-color, 20%), .5) $white !default;
$sidebar-agros-nav-tabs-link-hover-bg:             $white !default;
$sidebar-agros-nav-tabs-link-hover-border-color:   rgba(darken($border-color, 20%), .5) rgba(darken($border-color, 20%), .5) $white !default;
$sidebar-agros-tab-content-borders:                ( top: 1px solid rgba(darken($border-color, 20%), .5)) !default;

$sidebar-agros-footer-bg:       rgba($agros-black, .2) !default;
$sidebar-agros-footer-borders:  0 !default;

$sidebar-agros-minimizer-borders:                0 !default;
$sidebar-agros-minimizer-bg:                     rgba(0, 0, 0, .05) !default;
$sidebar-agros-minimizer-indicator-color:        $gray-600 !default;
$sidebar-agros-minimizer-indicator:              str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='#{$sidebar-agros-minimizer-indicator-color}' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E"), "#", "%23") !default;
$sidebar-agros-minimizer-hover-bg:               rgba(0, 0, 0, .1) !default;
$sidebar-agros-minimizer-hover-indicator-color:  $gray-700 !default;
$sidebar-agros-minimizer-hover-indicator:        str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='#{$sidebar-agros-minimizer-hover-indicator-color}' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E"), "#", "%23") !default;


//Sidebar eoc Theming
$sidebar-variants-map: (
    (
      agros: (
        "sidebar-color":                               $sidebar-agros-color,
        "sidebar-bg":                                  $sidebar-agros-bg,
        "eoc-theme-sidebar-bg":                       0,
        "sidebar-borders":                             $sidebar-agros-borders,
        "eoc-theme-sidebar-borders":                  0,
        "sidebar-brand-color":                         $sidebar-agros-brand-color,
        "sidebar-brand-bg":                            $sidebar-agros-brand-bg,
        "sidebar-header-bg":                           $sidebar-agros-header-bg,
        //"sidebar-form-border":                         $sidebar-dark-form-border,
        //"sidebar-form-bg":                             $sidebar-dark-form-bg,
        //"sidebar-form-color":                          $sidebar-dark-form-color,
        //"sidebar-form-placeholder-color":              $sidebar-dark-form-placeholder-color,
        "sidebar-nav-title-color":                     $sidebar-agros-nav-title-color,
        "sidebar-nav-link-color":                      $sidebar-agros-nav-link-color,
        "sidebar-nav-link-bg":                         $sidebar-agros-nav-link-bg,
        "sidebar-nav-link-icon-color":                 $sidebar-agros-nav-link-icon-color,
        "sidebar-nav-link-borders":                    $sidebar-agros-nav-link-borders,
        "sidebar-nav-link-hover-color":                $sidebar-agros-nav-link-hover-color,
        "sidebar-nav-link-hover-bg":                  $sidebar-agros-nav-link-hover-bg,
        "sidebar-nav-link-hover-icon-color":           $sidebar-agros-nav-link-hover-icon-color,
        "sidebar-nav-link-hover-borders":              $sidebar-agros-nav-link-hover-borders,
        "sidebar-nav-link-active-color":               $sidebar-agros-nav-link-active-color,
        "sidebar-nav-link-active-bg":                  $sidebar-agros-nav-link-active-bg,
        "sidebar-nav-link-active-icon-color":          $sidebar-agros-nav-link-active-icon-color,
        "sidebar-nav-link-active-borders":             $sidebar-agros-nav-link-active-borders,
        //"sidebar-nav-link-disabled-color":             $sidebar-dark-nav-link-disabled-color,
        //"sidebar-nav-link-disabled-bg":                $sidebar-dark-nav-link-disabled-bg,
        //"sidebar-nav-link-disabled-icon-color":        $sidebar-dark-nav-link-disabled-icon-color,
        //"sidebar-nav-link-disabled-borders":           $sidebar-dark-nav-link-disabled-borders,
        "sidebar-nav-dropdown-color":                  $sidebar-agros-nav-dropdown-color,
        "sidebar-nav-dropdown-bg":                     $sidebar-agros-nav-dropdown-bg,
        "sidebar-nav-dropdown-borders":                $sidebar-agros-nav-dropdown-borders,
        "sidebar-nav-dropdown-indicator-color":        $sidebar-agros-nav-dropdown-indicator-color,
        "sidebar-nav-dropdown-indicator":              $sidebar-agros-nav-dropdown-indicator,
        "sidebar-nav-dropdown-indicator-hover-color":  $sidebar-agros-nav-dropdown-indicator-hover-color,
        "sidebar-nav-dropdown-indicator-hover":        $sidebar-agros-nav-dropdown-indicator-hover,
        "sidebar-nav-tabs-border-color":               $sidebar-agros-nav-tabs-border-color,
        "sidebar-nav-tabs-link-active-bg":             $sidebar-agros-nav-tabs-link-active-bg,
        "sidebar-nav-tabs-link-active-border-color":   $sidebar-agros-nav-tabs-link-active-border-color,
        "sidebar-nav-tabs-link-hover-bg":              $sidebar-agros-nav-tabs-link-hover-bg,
        "sidebar-nav-tabs-link-hover-border-color":    $sidebar-agros-nav-tabs-link-hover-border-color,
        "sidebar-tab-content-borders":                 $sidebar-agros-tab-content-borders,
        "sidebar-footer-bg":                           $sidebar-agros-footer-bg,
        "sidebar-footer-borders":                      $sidebar-agros-footer-borders,
        "sidebar-minimizer-borders":                   $sidebar-agros-minimizer-borders,
        "sidebar-minimizer-bg":                        $sidebar-agros-minimizer-bg,
        "sidebar-minimizer-indicator-color":           $sidebar-agros-minimizer-indicator-color,
        "sidebar-minimizer-indicator":                 $sidebar-agros-minimizer-indicator,
        "sidebar-minimizer-hover-bg":                  $sidebar-agros-minimizer-hover-bg,
        "sidebar-minimizer-hover-indicator-color":     $sidebar-agros-minimizer-hover-indicator-color,
        "sidebar-minimizer-hover-indicator":           $sidebar-agros-minimizer-hover-indicator
      )
    )
);

/*
  Import the _variables.scss file into your react .js files as follows e.g.:
        import brandCyta from "./path/to/scss/_variables.scss"

  Then you can have access to the :export variables e.g:
        color = brandCyta.cytaGreen // brandCyta.{exported variable name}

*/
:export {
  agrosYellow: $agros-yellow ;
  agrosYellowDark: $agros-yellow-dark;
  agrosGreen: $agros-green ;
  eocOrange: $eoc-orange;
  agrosBlackGrey: $agros-black-grey;
  agrosBlack: $agros-black;
}
